<template>
  <div class="form-item other-list" v-show="visible">
    <div style="display:flex; width: 100%; align-items: center; justify-content: flex-start;">
      <span class="ve-list-widget label">{{localized(`label`)}} {{otherlist.length}}/{{fatherContent.controlReference.max}}</span>
    </div>
    <el-button
      style="position: absolute;top: -20px;right: 0px;"
      type="text"
      @click="addListItem"
      v-if="!disabled"
      :style="{opacity: (otherlist.length === fatherContent.controlReference.max || cannotEdit) ? .5 : 1}"
      :disabled="otherlist.length === fatherContent.controlReference.max || cannotEdit"
    >
      <img
        src="@/assets/icons/dcu/add.png"
        style="position: relative;z-index: 1001"
        :style="{opacity: disabled ? '.5' : 1}"
        width="30px"
      />
    </el-button>
    <div class="ve-list-border">
      <div v-for="(item,index) in otherlist" :key="'otherlist'+item.id">
        <div class="delete-container" v-if="!disabled">
          <div class="horizontal-line" v-show="index > 0"></div>
          <el-button
            style="margin-right: 0px;"
            type="text"
            v-show="index > 0"
            :disabled="item.minLength === otherlist.length || disabled"
            @click="removeListItem(index)"
          >
            <img
              src="@/assets/icons/dcu/delete.png"
              style="position: relative;z-index: 1001"
              :style="{opacity: disabled ? '.5' : 1}"
              width="30px"
            />
          </el-button>
        </div>
        <component
          v-if="registerTemplateName_SAVE.includes(`C${item.itemType.type.replace(/-/g, '')}`)"
          :is="`C${item.itemType.type.replace(/-/g, '')}`"
          ref="childComponents"
          :jsonContent="item.itemType"
          :fatherContent="item.itemType"
          :father="item"
          :datamodel="getContent(newJsonContext[index], item.itemType.key)"
          :jsonReferenceListContent="jsonReferenceListContent"
          @fatherCall="setUpdateJSON"
          @updateRefDcu="updateRefDcuData"
          @cacheDatamodel="cacheDatamodelData"
          @addListItems="addListItems"
          @removeListContent="removeListContent"
          :contextData="contextData"
          :host="hostKey"
          :listHost="dataKey + '.' + index"
          :editStatus="editStatus"
          :disabled="disabled"
          :contentLimit="contentLimit"
        ></component>
        <C00000000000000000000000000000000
          :jsonContent="item.itemType || []"
          ref="childComponents"
          :jsonReferenceListContent="jsonReferenceListContent"
          :jsonLanguageContent="jsonLanguageContent"
          :fatherContent="item.itemType"
          :father="item"
          :datamodel="getContent(newJsonContext[index], item.itemType.key)"
          @fatherCall="setUpdateJSON"
          @updateRefDcu="updateRefDcuData"
          @cacheDatamodel="cacheDatamodelData"
          @addListItems="addListItems"
          @removeListContent="removeListContent"
          :contextData="contextData"
          :host="hostKey"
          :listHost="dataKey + '.' + index"
          :editStatus="editStatus"
          :disabled="disabled"
          :contentLimit="contentLimit"
          v-else
        ></C00000000000000000000000000000000>
      </div>
    </div>
  </div>
</template>

<script>
let INTSET = 0;
import language from '@/utils/lang';
import { mapGetters } from 'vuex'
export default {
  uuid: "00000000-0000-0000-0000-000000000008",
  created() {
    var ROLL = 1;
    let jsonCtxLength = this.jsonContext ? this.jsonContext.length : 1;
    let modelLength = this.datamodel && this.datamodel.length || 1;
    ROLL = jsonCtxLength > modelLength ? jsonCtxLength : modelLength;

    for (let index = 0; index < ROLL; index++) {
      let temp = JSON.parse(JSON.stringify(this.jsonContent));
      temp.id = INTSET++;
      this.otherlist.push(temp);
    }
  },
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host","listHost", "jsonReferenceListContent", "jsonLanguageContent", "contextData", "editStatus"],
  components: {},
  data() {
    return {
      itemdata: "",
      otherlist: [],
      callbackJSON: [],
      newJsonContext: this.datamodel || []
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let selfKeyPath = this.listHost + '.' + this.fatherContent.key
      let isDisabled = this.getDisabledKeyPathList.filter(item => {
        return item.startsWith(selfKeyPath)
      })
      // if(isDisabled.length > 0){
      //   return
      // }
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath || (isDisabled.length > 0)
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey(){
      return this.host + "." + this.fatherContent.key
    },
    contentLimit() {
      return this.fatherContent.controlReference.max - this.newJsonContext.length
    }
  },
  watch: {

  },
  methods: {
    getContent(data, key) {
      if (data) {
        try {
          if (data[key]) {
            return data[key];
          } else if (data.value !== undefined && data.key !== undefined) {
            return data;
          } else if (data[key] === '') {
            console.log(data[key], 'data[key]');
            return undefined; // 有key但是无数据 2022/11/14
          } else {
            console.log(data.value, 'data.value');
            return data.value === false || data === false
                    ? false
                    : data.value || data;
          }
        } catch (error) {
          return data;
        }
      } else {
        return undefined;
      }
    },
    updateRefDcuData(data){
      this.$emit("updateRefDcu", data)
    },
    cacheDatamodelData(){
      this.$emit("cacheDatamodel")
    },
    addListItem() {

      let temp = JSON.parse(JSON.stringify(this.jsonContent));
      temp.id = INTSET++;
      this.otherlist.push(temp);
    },
    addListItems(val) {

      let disabledKeyPath = this.listHost + '.' + this.fatherContent.key
      let isDisabled = this.getDisabledKeyPathList.filter(item => {
        return item.startsWith(disabledKeyPath)
      })
      if(isDisabled.length > 0){
        return
      }

      setTimeout(() => {
        let Motion = [];
        let IndexSet = [];
        /// 1. 暂存非空数据
        if (this.$refs.childComponents !== undefined) {
          Motion = [];
          for (let index = 0; index < this.$refs.childComponents.length; index++) {
            const element = this.$refs.childComponents[index];
            if (element.formLabelAlign.filename !== "") {
              // 过滤空file
              Motion.push(element.formLabelAlign.value || element.formLabelAlign);
            }
            console.log("filter_exist_components", element.formLabelAlign.filehash)
            if(!element.formLabelAlign.filehash){
              IndexSet.push(index)
            }
          }
        }
        console.log("indexSet", IndexSet)
        console.log("this_otherlist", JSON.parse(JSON.stringify(this.otherlist)))
        /// 2. 移除空控件
        IndexSet.reverse().forEach(index => {
          this.otherlist.splice(index, 1)
          console.log("this_otherlist_deleting", JSON.parse(JSON.stringify(this.otherlist)))
          console.log("deleting_index", index)
        })

        console.log("this_otherlist_removed", JSON.parse(JSON.stringify(this.otherlist)))
        Motion = Motion.filter(item => {
          return item && Object.keys(item).length > 0
        });

        this.newJsonContext = Motion
        let length = val.value.length
        /// 3. 扩容控件数组
        for (let index = 0; index < length; index++) {
          let temp = JSON.parse(JSON.stringify(this.jsonContent));
          temp.id = INTSET++;
          this.otherlist.push(temp);
        }
        console.log("this_otherlist_added", JSON.parse(JSON.stringify(this.otherlist)))
        this.newJsonContext = this.newJsonContext.concat(val.value)
        this.$forceUpdate()
      }, 100);
    },
    removeListContent(val) {
      let filehash = val.value && val.value.filehash
      if(filehash){
        this.newJsonContext = this.newJsonContext.filter(item => {
          return item.filehash !== filehash
        })
      }
    },
    removeListItem(index) {
      let disabledKeyPath = this.listHost + '.' + this.fatherContent.key + '.' + index
      let isDisabled = this.getDisabledKeyPathList.filter(item => {
        return item.startsWith(disabledKeyPath)
      })
      if(isDisabled.length > 0){
        return
      }

      /// check child item
      this.otherlist.splice(index, 1);
      setTimeout(() => {
        let Motion = [];
        if (this.$refs.childComponents !== undefined) {
          Motion = [];
          this.$refs.childComponents.forEach(element => {
            if (element.formLabelAlign.filename !== "") {
              // 过滤空file
              Motion.push(element.formLabelAlign.value || element.formLabelAlign);
            }
          });
        }
        if(this.jsonContent.itemType.type === '00000000-0000-0000-0000-000000100005'){
          this.newJsonContext = Motion.filter(item => {
            return item && Object.keys(item).length > 0
          });
        }else{
          this.newJsonContext = Motion;
        }
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: Motion
        });
      }, 100);
    },
    setUpdateJSON(data) {
      this.$emit("fatherCall", {
        key: this.fatherContent.key,
        value: this.getNewValue()
      });
    },
    getNewValue() {
      if (this.$refs.childComponents !== undefined) {
        this.callbackJSON = [];
        if(this.jsonContent.itemType.type === '00000000-0000-0000-0000-000000100005'){
          this.newJsonContext = [];
        }
        this.$refs.childComponents.forEach(element => {

          if (element.formLabelAlign && element.formLabelAlign.value != undefined) {

            if (element.jsonContent && element.jsonContent.type === '00000000-0000-0000-0000-000000100013') {
              /// key-value params
              const elementAlign = JSON.parse(JSON.stringify(element.formLabelAlign))
              if(element.formLabelAlign && Object.keys(elementAlign).length > 0){
                  this.callbackJSON.push( element.formLabelAlign )
              }
            } else if (Object.prototype.toString.call(element.formLabelAlign.value) === '[object Array]' && (element.jsonContent && element.jsonContent.type === '00000000-0000-0000-0000-000000100007')) {
              /// vidlist
              if(element.formLabelAlign.value.length > 0){
                  this.callbackJSON.push({
                    vidlist:element.formLabelAlign.value,
                    num: element.formLabelAlign.value.length,
                    type: element.vidType || 0
                  })
              }
            } else if (element.jsonContent && element.jsonContent.type === '00000000-0000-0000-0000-000000100002') {
              /// fix gps
              let elementValue = element.formLabelAlign.value
              this.callbackJSON.push(elementValue)
            } else if (element.callBackJson) {
              // object
              JSON.stringify(element.formLabelAlign.value) !== '{}' ? this.callbackJSON.push(element.formLabelAlign.value) : ''
            } else {
              /// basic element
              let elementValue = {[element.fatherContent.key]:element.formLabelAlign.value}
              this.callbackJSON.push(elementValue)
            }
          } else if (element.formLabelAlign && element.formLabelAlign.hasOwnProperty('filelocation')) {
            // file
            if (element.formLabelAlign.filelocation) {
              this.callbackJSON.push( element.formLabelAlign )
            }
            if(this.jsonContent.itemType.type === '00000000-0000-0000-0000-000000100005' && element.formLabelAlign.filelocation){
              this.newJsonContext.push( element.formLabelAlign )
            }

          } else {

            const elementAlign = JSON.parse(JSON.stringify(element.formLabelAlign))
            if(element.formLabelAlign && Object.keys(elementAlign).length > 0){
                this.callbackJSON.push( element.formLabelAlign )
            }
          }
        });
      }

      if (this.callbackJSON.length <= 0) {
        return undefined
      }

      return this.callbackJSON;
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    }
  },
  mounted() {
    // console.log(this.registerTemplateName_SAVE, 'this,dadasdsd');
  }
};
</script>
<style lang='scss' scoped>
.other-list {
  // border: 1px solid #ccc;
  margin-top: 28px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}
.ve-list-border {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
}
.delete-container {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.horizontal-line {
  width: calc(100% - 40px);
  height: 1px;
  margin-top: 26px;
  border-bottom: 1px dashed #ccc;
}
.ve-list-widget {
  font-size: 16px;
  font-weight: 600;
}
</style>
