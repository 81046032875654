<template>
  <div class="form-item" v-show="visible">
    <div class="collapse-title" :style="{backgroundColor: selectColor}" @click="closeContent" v-show="!isListItem">
      <span class="collapse-title-span" v-if="!isListItem">{{localized(`label`)}}</span>
      <el-popover placement="bottom" width="60" :ref="'popover-' + popoverKey" trigger="hover">
        <div
          slot="reference"
          @click.stop="handleClick"
          :style="{backgroundColor: selectColor}"
          class="popover-title-wrap"
        >
        </div>
        <template>
          <div class="color-options">
            <div v-for="(element, index) in options" :key="index" class="color-wrap">
              <div
                v-if="index==options.length-1"
                :style="{backgroundColor: element, flex: '1', height: '40px', border: 'none',textAlign:'center', display: 'flex', alignItems: 'center', justifyContent: 'center'}"
                @click="changeCC(element)"
              >
                <span>clean</span>
              </div>
              <div
                v-else
                :style="{backgroundColor: element, width: '100%', height: '100%', border: 'none'}"
                @click="changeCC(element)"
              ></div>
            </div>
          </div>
        </template>
      </el-popover>
    </div>
    <el-collapse-transition v-if="!isListItem">
      <!-- <div v-show="showContent"> -->
        <el-form
          ref="childForm"
          @submit.native.prevent
          :model="formLabelAlign"
          label-position="left"
          class="other-object"
          :style="{borderColor: borderColor}"
          @showValidFail="showValidFailed"
        >
          <el-form-item prop="value">
            <div class="form-content">
              <div v-for="(item,index) in attributes" :key="'basemodel'+index">
                <component
                  v-if="registerTemplateName_SAVE.includes(`C${item.type.replace(/-/g, '')}`)"
                  :is="`C${item.type.replace(/-/g, '')}`"
                  :jsonContent="item.dataReference || undefined"
                  :fatherContent="item"
                  :father="item"
                  :datamodel="datamodel?datamodel[item.key]===false?false:(datamodel[item.key]):undefined"
                  :jsonReferenceListContent="jsonReferenceListContent"
                  @fatherCall="setUpdateJSON"
                  @updateRefDcu="updateRefDcuData"
                  @switchChange="switchChange"
                  :disabled="disabled"
                  :host="hostKey"
                  ref="childComponents"
                  :contextData="contextData"
                ></component>
                <C00000000000000000000000000000000
                :jsonContent="item.dataReference || []"
                ref="childComponents"
                :editStatus="editStatus"
                :jsonReferenceListContent="jsonReferenceListContent"
                :jsonLanguageContent="jsonLanguageContent"
                :fatherContent="item"
                :father="item"
                  :datamodel="datamodel?datamodel[item.key]===false?false:(datamodel[item.key]):undefined"
                  @fatherCall="setUpdateJSON"
                  @updateRefDcu="updateRefDcuData"
                  :disabled="disabled"
                  :host="hostKey"
                  :contextData="contextData"
                v-else
              ></C00000000000000000000000000000000>
              </div>
            </div>
          </el-form-item>
        </el-form>
      <!-- </div> -->
    </el-collapse-transition>
    <div v-else>
      <el-form
          ref="childForm"
          @submit.native.prevent
          :model="formLabelAlign"
          label-position="left"
          class="other-object"
          :style="{borderColor: borderColor}"
          @showValidFail="showValidFailed"
        >
          <el-form-item prop="value">
            <div class="form-content">
              <div v-for="(item,index) in attributes" :key="'basemodel'+index">
                <component
                  v-if="registerTemplateName_SAVE.includes(`C${item.type.replace(/-/g, '')}`)"
                  :is="`C${item.type.replace(/-/g, '')}`"
                  :jsonContent="item.dataReference || undefined"
                  :fatherContent="item"
                  :father="item"
                  :datamodel="datamodel?datamodel[item.key]===false?false:(datamodel[item.key]):undefined"
                  :jsonReferenceListContent="jsonReferenceListContent"
                  @fatherCall="setUpdateJSON"
                  @updateRefDcu="updateRefDcuData"
                  @cacheDatamodel="cacheDatamodelData"
                  @switchChange="switchChange"
                  :disabled="disabled"
                  :host="hostKey"
                  :listHost="dataKey"
                  ref="childComponents"
                  :contextData="contextData"
                  :editStatus="editStatus"
                ></component>
                <C00000000000000000000000000000000
                :jsonContent="item.dataReference || []"
                ref="childComponents"
                :editStatus="editStatus"
                :jsonReferenceListContent="jsonReferenceListContent"
                :jsonLanguageContent="jsonLanguageContent"
                :fatherContent="item"
                :father="item"
                  :datamodel="datamodel?datamodel[item.key]===false?false:(datamodel[item.key]):undefined"
                  @fatherCall="setUpdateJSON"
                  @updateRefDcu="updateRefDcuData"
                  @cacheDatamodel="cacheDatamodelData"
                  :disabled="disabled"
                  :host="hostKey"
                  :listHost="dataKey"
                  :contextData="contextData"
                v-else
              ></C00000000000000000000000000000000>
              </div>
            </div>
          </el-form-item>
        </el-form>
    </div>
  </div>
</template>

<script>
import language from '@/utils/lang';
import callApp from "@/services/sdk";
import { mapGetters } from 'vuex'
export default {
  uuid: "00000000-0000-0000-0000-000000000011",
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host", "listHost", "jsonReferenceListContent", "jsonLanguageContent", "contextData", "editStatus", "father"],
  data() {
    return {
      itemdata: "",
      callBackJson: {},
      formLabelAlign: {},
      label: "",
      copyitem: {},
      options: [
        "#26ccd8",
        "#5f45ff",
        "#47aee3",
        "#d5d6d8",
        "#96d7f9",
        "#f9e264",
        "#f47a75",
        "#50c48f",
        "#ffffff"
      ],
      selectColor: "",
      tempColor: "",
      // borderColor: "#ccc",
      popoverKey: "",
      showContent: false
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    attributes() {
      return (
        (this.jsonContent.dataReference &&
          this.jsonContent.dataReference.attributes) ||
        this.jsonContent.attributes ||
        []
      );
    },
    cannotEdit(){
      let failure = this.formStatus === 'failureedit'
      let draft = this.formStatus === 'draftedit'
      let initStatus = this.formStatus === 'init'
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey) || (!failure && !draft && !initStatus)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    hostKey(){
      return this.host + "." + this.fatherContent.key
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    isListItem() {
      return this.father && this.father.itemType && Object.keys(this.father.itemType).length > 0
    },
    borderColor() {
			return (this.selectColor == '' || this.selectColor == '#ffffff') ? '#ccc' : this.selectColor
		}
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.value
        });
      },
      deep: true
    }
  },
  methods: {
    updateRefDcuData(data){
      this.$emit("updateRefDcu", data)
    },
    cacheDatamodelData(){
      this.$emit("cacheDatamodel")
    },
    setUpdateJSON(data) {
      this.callBackJson[data.key] = data.value;
      if (data.value == undefined) {
        delete this.callBackJson[data.key];
      }

      let updataJson = JSON.parse(JSON.stringify(this.callBackJson))
      let emmitValue = Object.keys(updataJson).length > 0 ? updataJson : undefined
      this.formLabelAlign.value = emmitValue

      this.$emit("fatherCall", {
        key: this.fatherContent.key,
        value: emmitValue
      });
    },
    localized(key) {
      // return ""
      if(!this.fatherContent.displayReference) {
        return ""
      }
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    },
    closeContent() {
      this.showContent = !this.showContent;
    },
    handleClick(e) {},
    changeCC(element) {
      // this.selectColor = element;
      // this.borderColor =
      //   this.selectColor == "" || this.selectColor == "#ffffff"
      //     ? "#ccc"
      //     : this.selectColor;
      // this.$refs[`popover-` + this.popoverKey].doClose();
      // let name = this.$store.state.uuid + this.fatherContent.key;
      // name &&
      //   callApp(
      //     "setOtherObjectColor",
      //     {
      //       type: "storage.updateOtherObjectColor",
      //       data: {
      //         key: name,
      //         val: element
      //       }
      //     },
      //     "cbSetOtherObjectColor"
      //   );
    },
    switchChange(val){

				if(val && val === true){
					this.selectColor = "#50c48f"
				}else{
					this.selectColor = "#f47a75"
        }
    },
    showValidFailed() {
      this.showContent = true;
      this.selectColor =
        this.selectColor.length > 0 ? this.selectColor : "#ffffff";
      var tColor = JSON.parse(JSON.stringify(this.selectColor));
      this.selectColor = "red";
      var that = this;
      setTimeout(function() {
        that.selectColor = tColor;
      }, 3000);
    }
  },
  created() {
    var now = new Date();
    this.popoverKey = now.getTime();
  },
  mounted() {
    if (this.jsonContent.attributes) {
      this.label =
        this.fatherContent &&
        this.fatherContent.displayReference &&
        this.fatherContent.displayReference.label;
    }
    if (
      this.jsonContent.dataReference &&
      this.jsonContent.dataReference.attributes
    ) {
      this.label = "";
    }
    let name = this.$store.state.uuid + this.fatherContent.key;
    name &&
      callApp(
        "getOtherObjectColor",
        {
          type: "storage.getOtherObjectColor",
          data: { key: name }
        },
        "cbGetOtherObjectColor"
      ).then(data => {
        this.selectColor = data;
      });

      if(this.isListItem) {
        this.showContent = true
      }
      this.formLabelAlign.value  = this.datamodel
  }
};
</script>
<style lang='scss' scoped>
.ve-list > span {
  font-weight: 600;
  color: #000000;
  line-height: 32px;
}
.collapse-title-span {
  font-weight: 600;
  text-shadow: 0 0 1px #fff;
  color: #333;
  font-size:13px;
  /* line-height: 20px; */
}
.collapse-title-warning {
  font-weight: 600;
  text-shadow: 0 0 1px #fff;
  color: #aa0000;
}

.other-object {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
}
.collapse-title {
  /* width: 100%; */
  display: flex;
  /* min-height: 50px; */
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px 0;
  border-radius: 10px;
}
.color-options {
  display: flex;
  width: 150px;
  height: 185px;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
}
.color-wrap {
  /* flex: 1; */
  width: calc(calc(100% / 3) - 10px);
  margin: 2px 5px;
  height: 50px;
  box-sizing: border-box;
}
.change-color-btn {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  text-align: center;
  img {
    margin-top: 5px;
    margin-left: 5px;
    width: 30px;
    height: 30px;
  }
}
.popover-title-wrap {
  display: flex;
  align-items: center;
}
</style>
