<template>
  <!-- 为发货管理页面 - 批次信息使用, model 返回的 uuid 相同, 渲染了相同的组件，因此要使用 key来做区分  key=receivevidlist -->
  <div class="item-content" v-show="visible">
    <el-form ref="childForm" @submit.native.prevent :rules="rules" :model="formLabelAlign">

      <div class="item-tooltip-title">
        <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          v-if="fatherContent.displayReference && fatherContent.displayReference.description"
        >
          <div slot="content">
            {{ fatherContent.displayReference.description }}
          </div>
          <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
        </el-tooltip>
      </div>
      <el-form-item prop="value">
        <el-select
          style="width:100%"
          :placeholder="content.displayReference.prompt"
          v-model="itemdata"
          size="small"
          clearable
          :disabled="cannotEdit"
          @change="handleChangeBatchCode"
        >
          <el-option
            v-for="item in batchInfoList"
            :key="item.id"
            :label="item.vid_info.custom_id"
            :value="item.id"
          />
        </el-select>
        <div class="options-info" v-if="Object.keys(currentSelect).length !== 0">
          <div>
            <el-image :src="imageUrl" :fit="'contain'" />
          </div>
          <div class="product-info">
            <span>{{ $t("common.product_name") }}: {{ currentSelect.product && currentSelect.product.name }}</span>
            <span>{{ $t("common.product_count") }}: {{ currentSelect.amount }}</span>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import language from '@/utils/lang';
import { mapGetters } from 'vuex';
import VeTrueApi from '@/api/vetrue';

export default {
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host","listHost", "contextData", "editStatus"],
  data() {
    return {
      itemdata: "",
      formLabelAlign: { value: "" },
      batchInfoList: [],
      currentSelect: {},
      imageUrl: '',
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    content() {
      return Object.keys(this.jsonContent) > 0
        ? this.jsonContent
        : this.fatherContent;
    },
    rules() {
      return {
        value: [
          {
            required: this.fatherContent.controlReference.required,
            message: this.$t('app.required_error') ,
            trigger: 'blur'
          }
        ]
      }
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        if (!val.value) return
        this.$emit("confirm", val.value);  // 往vid父组件推数据
      },
      deep: true
    }
  },
  methods: {
    spliceUrl(location) {
      return window.Config.base_url + window.Config.image_path + `/${location}`;
    },
    async getBatchInfoSelectData() {
      const query = this.$route.query;
      const project_uuid = query.project_uuid;
      const params = { project_uuid, dcp_data_id: query.biz_data_id }; // 接口新增参数 dcp_data_id
      this.batchInfoList = await VeTrueApi.getProductBindSelectList({ query, params });
    },
    handleChangeBatchCode(val) {
      const current = this.batchInfoList.filter(item => item.id === val)[0];
      this.currentSelect = current;
      this.imageUrl = this.spliceUrl(current && current.product.image);

      if (!current) return
      const info = current && current.vid_info;
      const value = `${info.vid},${info.custom_id}`; // 提交的数据需要逗号拼接组合
      const data = {
        vidlist: [value],
        type: 1,
        num: 1
      };
      this.itemdata = info.custom_id;
      this.formLabelAlign.value = data;
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    }
  },
  created() {},
  mounted() {
    // this.formLabelAlign.value = this.datamodel;
    if (this.datamodel !== undefined) {
      if (Object.keys(this.datamodel).length === 0) {
        this.formLabelAlign.value = ''
        this.itemdata = '';
      } else {
        this.formLabelAlign.value = this.datamodel || '';
        const vidList = this.datamodel.vidlist[0];
        this.itemdata = vidList.split(',')[1] || ''; // select 要进行回显匹配
      }
    } else {
      this.formLabelAlign.value = this.jsonContent.defaultValue || this.fatherContent.displayReference.defaultValue || ''
      this.itemdata = this.jsonContent.defaultValue || this.fatherContent.displayReference.defaultValue || ''
    }
    this.getBatchInfoSelectData();
  }
};
</script>
<style lang='scss' scoped>
.item-content {
  padding-top: 10px;
  text-align: left;
  margin-bottom: 5px;
}

.options-info {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding-bottom: 0px;
}

.product-info {
  display: flex;
  flex-direction: column;
  line-height: 30px;
}

::v-deep .el-image {
  height: 50px;
  width: 50px;
  & > img {
    height: 50px;
    width: 50px;
  }
}

</style>
