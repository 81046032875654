<template>
  <div class="item-content" v-show="visible">
    <el-form ref="childForm" @submit.native.prevent :rules="rules" :model="formLabelAlign">

      <div class="item-tooltip-title">
        <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          v-if="fatherContent.displayReference && fatherContent.displayReference.description"
        >
          <div slot="content">
            {{ fatherContent.displayReference.description }}
          </div>
          <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
        </el-tooltip>
      </div>
      <el-form-item prop="value">
        <el-select
          style="width:100%"
          :placeholder="content.displayReference.prompt"
          v-model="formLabelAlign.value"
          size="small"
          clearable
          :multiple="content.controlReference.max>1"
          :multiple-limit="content.controlReference.max"
          :disabled="cannotEdit"
          @change="filterTextAction"
        >

          <el-input
            style="width: 95%; padding: 0 5px 5px 5px"
            :placeholder="$t('app.components_render_placeholder_filter')"
            v-model="filterText"
          ></el-input>
          <el-option
            v-for="item in content.controlReference.options"
            v-show="item.label.includes(filterText)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import language from '@/utils/lang';
import { mapGetters } from 'vuex'
export default {
  uuid: "00000000-0000-0000-0000-000000000009",
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host","listHost", "contextData", "editStatus"],
  data() {
    return {
      itemdata: "",
      formLabelAlign: { value: "" },
      filterText: ""
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    content() {
      return Object.keys(this.jsonContent) > 0
        ? this.jsonContent
        : this.fatherContent;
    },
    rules() {
      if (this.content.controlReference.max > 1) {
        return {
          value: [
            {
              type: 'array',
              required: this.content.controlReference.required,
              message: this.$t('app.required_error'),
              trigger: 'change'
            },
            {
              type: 'array',
              min: this.content.controlReference.min,
              max: this.content.controlReference.max,
              message: `At least choose ${this.content.controlReference.min} items`,
              trigger: 'change'
            }
          ]
        }
      } else {
        return {
          value: [
            {
              required: this.content.controlReference.required,
              message: this.$t('app.required_error'),
              trigger: 'change'
            }
          ]
        }
      }
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.value
        });
      },
      deep: true
    }
  },
  methods: {
    filterTextAction(e) {
      this.filterText = ''
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    }
  },
  created() {},
  mounted() {
    this.formLabelAlign.value = this.datamodel;
  }
};
</script>
<style lang='scss' scoped>
.item-content {
  padding-top: 10px;
  text-align: left;
  margin-bottom: 5px;
}
</style>
