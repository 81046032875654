<template>
  <!-- 发货管理页面中的收获方 h5 和 web 都是一个下拉框组件 (共用) -->
  <div class="item-content" v-show="visible">
    <el-form ref="childForm" @submit.native.prevent :rules="rules" :model="formLabelAlign">

      <div class="item-tooltip-title">
        <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          v-if="fatherContent.displayReference && fatherContent.displayReference.description"
        >
          <div slot="content">
            {{ fatherContent.displayReference.description }}
          </div>
          <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
        </el-tooltip>
      </div>
      <el-form-item prop="value">
        <el-select
          style="width:100%"
          :placeholder="content.displayReference.prompt"
          v-model="formLabelAlign.value"
          size="small"
          clearable
          :disabled="cannotEdit"
          @change="handleChangeReceiveSelect"
        >
          <el-option
            v-for="item in receiveList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import language from '@/utils/lang';
import { mapGetters } from 'vuex';
import VeTrueApi from '@/api/vetrue';

export default {
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host","listHost", "contextData", "editStatus"],
  data() {
    return {
      itemdata: "",
      formLabelAlign: { value: "" },
      filterText: "",
      receiveList: []
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    content() {
      return Object.keys(this.jsonContent) > 0
        ? this.jsonContent
        : this.fatherContent;
    },
    rules() {
      return {
        value: [
          {
            required: this.fatherContent.controlReference.required,
            message: this.$t('app.required_error') ,
            trigger: 'blur'
          }
        ]
      }
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        if (!val.value) return
        this.$emit("confirm", val.value);  // 往vid父组件推数据
      },
      deep: true
    }
  },
  methods: {
    handleChangeReceiveSelect(id) {
      const receive = this.receiveList.filter(item => item.id === id)[0];
      this.formLabelAlign.value = receive.name;
      this.$store.dispatch("setSelectReceive", receive);
    },
    async getReceiveSelectData() {
      const query = this.$route.query;
      const project_uuid = query.project_uuid;
      const params = { project_uuid };
      if (query.data_id) { // 修改的时候增加查询参数
        params.logistics_order_id = query.data_id;
      }
      this.receiveList = await VeTrueApi.getShipmentSelectList({ query, params });

      // 当修改时进入页面或者没有再选择收货方时会出现 selectReceive 无数据情况，从而导致提交出现收货部门的错误提示
      const name = this.datamodel;
      const receive = this.receiveList.filter(item => item.name === name)[0]; // 这里需要name唯一
      this.$store.dispatch("setSelectReceive", receive);
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    }
  },
  created() {},
  mounted() {
    // this.formLabelAlign.value = this.datamodel;
    if (this.datamodel !== undefined) {
      if (Object.keys(this.datamodel).length === 0) {
        this.formLabelAlign.value = ''
      } else {
        this.formLabelAlign.value = this.datamodel || '';
      }
    } else {
      this.formLabelAlign.value = this.jsonContent.defaultValue || this.fatherContent.displayReference.defaultValue || ''
    }
    this.getReceiveSelectData();
  }
};
</script>
<style lang='scss' scoped>
.item-content {
  padding-top: 10px;
  text-align: left;
  margin-bottom: 5px;
}
</style>
